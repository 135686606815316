import { useContext } from 'react';
import { AppContext } from '@context';
import Button from '@components/particles/Button';
import Text from '@components/particles/Text';
import List from '@components/particles/List';
import styles from '@styles/components/ProjectDetails.module.scss';

const ProjectDetails = ({ client, link, summary, details, ...props }) => {
    const { experience, misc } = useContext(AppContext);

    return (
        <>
            <div className={styles.projectDetails}>
                <Text styleName="h1" className={styles.client}>{client}</Text>
                <Text styleName="summary" className={styles.summary}>{summary}</Text>

                {link && <div className={styles.links}>
                    <Button href={link} target="_blank">{misc.projectLink}</Button>
                </div>}

                <Text tagName="div" styleName="book" className={styles.extra}>
                    <p>{experience.details}</p>
                    <List items={details} className={styles.details} />
                </Text>
            </div>
        </>
    );
}

export default ProjectDetails;