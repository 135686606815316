import { useContext } from 'react';
import { AppContext } from '@context';
import classNames from 'classnames';
import Text from '@components/particles/Text';
import ProjectSummary from '@components/ProjectSummary';
import styles from '@styles/components/ProjectTimeline.module.scss';
import groupBy from 'lodash.groupby';

const ProjectTimeline = () => {
    const { projects } = useContext(AppContext);
    
    const years = groupBy(projects, (project => project.date));  
    
    let counter = 0;

    const yearClasses = classNames(
        styles.year
    );
    
    return (
        <>
            <Text styleName="h1">Experience</Text>
            
            <div className={styles.projectTimeline}>
                {Object.keys(years).reverse().map((year, i) => {
                    const clients = years[year];
                    const key = `${year}-${i}`;

                    return (
                        <div key={key}>
                            <Text styleName="year" className={yearClasses}>{year}</Text>
                            {clients.map((p, i) => {
                                counter++;

                                return <ProjectSummary
                                    key={i}
                                    counter={counter}
                                    {...p}
                                />
                            })}
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default ProjectTimeline;