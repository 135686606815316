import Text from '@components/particles/Text';

const ErrorInfo = ({ error, ...props }) => {
    return (
        <>
            <Text styleName="h3">Sorry, something went wrong</Text>
            <Text>Please contact me via e-mail instead. Thanks!</Text>
        </>
    );
};

export default ErrorInfo;