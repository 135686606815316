import { useContext } from 'react';
import classNames from 'classnames';
import { AppContext } from '@context';
import Icon from '@components/particles/Icon';
import Text from '@components/particles/Text';
import { useMediaQueries } from '@hooks/useMediaQueries';
import styles from '@styles/components/Theme.module.scss';
import '@styles/themes.scss';

export const ThemeSwitch = ({ className, ...props }) => {
    const { darkTheme, setDarkTheme, misc } = useContext(AppContext);
    const { useLightTheme, useDarkTheme } = misc || {};

    const media = useMediaQueries();
    
    const toggle = () => setDarkTheme(!darkTheme);
    const label = darkTheme ? useLightTheme : useDarkTheme;

    const switchClasses = classNames(
        styles.toggle,
        className
    );

    const SwitchIcon = (props) => darkTheme ? <Icon icon="IconSun" {...props} /> : <Icon icon="IconMoon" {...props} />;
    
    return media.darkMode ? (
        <Text
            tagName="div"
            onClick={toggle}
            className={switchClasses}
        >
            <SwitchIcon>{label}</SwitchIcon>
        </Text>
    ) : null;
}

const Theme = ({ children, className, ...props }) => {
    const { theme, darkTheme } = useContext(AppContext);

    const mainClasses = classNames(
        'theme',
        theme,
        darkTheme && 'darkModeEnabled',
        className
    );

    return <div className={mainClasses}>{children}</div>;
};

export default Theme;