import { useContext, useEffect, useState } from 'react';
import { content } from '@root/package.json';
import { AppContext } from '@context';
import Text from '@components/particles/Text';
import List from '@components/particles/List';

const Print = () => {
    const { contact, intro, projects, services } = useContext(AppContext);

    const [email, setEmail] = useState(contact.email);
    const [phone, setPhone] = useState(contact.phone);

    useEffect(() => {
        setEmail(`hi@${content.domain}`);
    }, []);

    useEffect(() => {
        setPhone(`+44 0${contact.phone * 11}`);
    }, [contact.phone]);

    return (
        <>
            <Text styleName="h1">{contact.firstName} {contact.lastName}</Text>
            <Text>{intro.text}</Text>
            <Text styleName="h1">Contact</Text>
            <Text>{email}, {phone}</Text>
            <Text>{contact.city}, {contact.country}</Text>
            <Text styleName="h1">Experience</Text>
            {projects.map(p => {
                const { date, client, details } = p;

                return {
                    date,
                    client,
                    details
                }
            })
            .sort((a, b) => {
                return b.date - a.date;
            })
            .map(project => {
                return (
                    <>
                        <Text styleName="h3">{project.client}</Text>
                        <List items={project.details} />
                    </>
                );
            })}
            
            {services && services.columns && services.columns.map(c => {
                return (
                    <>
                        <Text styleName="h1">{c.title}</Text>
                        <List items={c.items} />
                    </>
                );
            })}
        </>
    );
}

export default Print;