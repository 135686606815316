import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from '@styles/components/Logo.module.scss';
import { content } from '@root/package.json';

const Logo = ({ link = '/', className }) => {
    const logoClasses = classNames(
        'logo',
        className,
        styles.logo
    );

    const [firstName, lastName] = content.title.split(' ');

    const LogoWrapper = ({ children }) => link ? <Link to={link}>{children}</Link> : children;

    return (
        <div className={logoClasses}>
            <div className={styles.logoTextContainer}>
                <LogoWrapper>
                    <span className={styles.logoText}>
                        <span className={styles.firstName}>{firstName}</span>
                        <span className={styles.lastName}>{lastName}</span>
                    </span>
                </LogoWrapper>
            </div>
        </div>
    );
}

export default Logo;