import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const isNestedRoute = pathname.split('/').length > 2;
    const isOverlayOpen = Boolean(document.querySelector('#overlay').children.length); // fixes a weird bug in iOS Safari where the overlay would not close completely
    
    if(!isNestedRoute && !isOverlayOpen) window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;