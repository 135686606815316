import path from "path"
import { useLocation, useRouteMatch, Link } from 'react-router-dom';
import classNames from 'classnames';
import slugify from 'voca/slugify';
import Text from '@components/particles/Text';
import List from '@components/particles/List';
import styles from '@styles/components/ProjectSummary.module.scss';

const ProjectSummary = ({ client, summary, role, tags, counter, ...props }) => {
    const location = useLocation()
    const { url } = useRouteMatch();
    const isRight = counter % 2;

    const projectSummaryClasses = classNames(
        styles.projectSummary,
        isRight ? styles.right : styles.left
    );

    return (
        <div className={projectSummaryClasses}>
            <Link to={{
                    pathname: path.join(url, slugify(client)),
                    state: {
                        background: location
                    }
                }}
            >
                <Text styleName="excerpt" className={styles.client}>{client}</Text>
                <Text className={styles.role}>{role}</Text>
                <Text tagName="div" styleName="large">
                    <List items={tags} graded className={styles.tags} />
                </Text>
            </Link>
        </div>
    );
}

export default ProjectSummary;