import { useEffect, useContext, useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from '@images/illustrations/25302-loader.json';

import { CSSTransition } from 'react-transition-group';
import ErrorInfo from '@components/ErrorInfo';
import Logo from '@components/Logo';
import Text from '@components/particles/Text';
import { AppContext } from '@context';
import { content } from '@root/package.json';
import { pageTransitionDuration } from '../scss/_variables.module.scss';
import styles from '@styles/components/Loader.module.scss';

const Loader = ({ children, ...props }) => {
    const { error, state, projects, menuLinks } = useContext(AppContext);
    const total = state.pendingRequests.length;
    const [showLoader, setShowLoader] = useState(true);

    const transitionDuration = parseInt(pageTransitionDuration);

    useEffect(() => {
        const isLoading = !(menuLinks && menuLinks.length && projects.length && total === 0);

        setTimeout(() => setShowLoader(isLoading), 500);
    }, [menuLinks, projects, total])

    return (
        <CSSTransition
            in={showLoader}
            classNames={{
                appearActive: styles['loader-appear-active'],
                enterActive: styles['loader-enter-active'],
                exitActive: styles['loader-exit-active'],
                exit: styles['loader-exit']
            }}
            timeout={transitionDuration}
            mountOnEnter
            unmountOnExit
        >
            <div className={styles.loader}>
                {error ? <ErrorInfo error={error} /> : (
                <>
                    <Logo link={false} className={styles.logo} />
                    <div className={styles.textWrapper}>
                        <Lottie
                            loop
                            animationData={animation}
                            play
                            className={styles.animation}
                        />
                        <Text styleName="large" className={styles.text}>{content.loading}</Text>
                    </div>
                </>
                )}
            </div>
        </CSSTransition>
    );
};

export default Loader;