import { useEffect, useContext, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import slugify from 'voca/slugify';
import { AppContext } from '@context';
import Overlay from '@components/Overlay';
import ProjectDetails from '@components/ProjectDetails';
import styles from '@styles/components/ProjectOverlay.module.scss';

const ProjectOverlay = ({ mainRoute, ...props }) => {
    const { projects } = useContext(AppContext);
    const [visible, setVisible] = useState(true);

    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const project = projects.find(img => slugify(img.client) === slugify(params.client));

    const onBeforeClose = () => {
        if(mainRoute) {
            history.push(mainRoute);
        } else {
            history.goBack();            
        }
    }
    
    useEffect(() => {
        return history.listen(() => setVisible(false))
    // eslint-disable-next-line
    }, [location])

    return project ? (
        <Overlay
            appear
            isVisible={visible}
            onBeforeClose={onBeforeClose}
            className={styles.projectOverlay}
            closeButtonClassName={styles.projectOverlayCloseButton}
            contentClassName={styles.projectOverlayContent}
        >
            <ProjectDetails {...project} />
        </Overlay>
    ) : null;
}

export default ProjectOverlay;