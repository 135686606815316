import { useContext } from 'react';
import classNames from 'classnames';
import { AppContext } from '@context';
import useBreadcrumb from '@hooks/useBreadcrumb';
import Portal from '@components/particles/Portal';
import Button from '@components/particles/Button';
import styles from '@styles/components/Guide.module.scss';

const Guide = () => {
    const { menuLinks } = useContext(AppContext);
    const b = useBreadcrumb();

    const currentIndex = menuLinks.findIndex(m => m.path === b);
    const prevIndex = currentIndex - 1;
    const nextIndex = currentIndex + 1;
    
    const nextLink = menuLinks[nextIndex];
    const prevLink = menuLinks[prevIndex];

    const guideClasses = classNames(
        styles.guide
    );

    return null;
    
    (
        <Portal className="app-guide">
            <div className={guideClasses}>
                {prevLink && <Button className={styles.prev} to={prevLink.path}>Back to: {prevLink.label}</Button>}
                {nextLink && <Button className={styles.next}  to={nextLink.path}>Next up: {nextLink.label}</Button>}
            </div>
        </Portal>
    );
};

export default Guide;