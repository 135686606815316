import { useState, useEffect } from 'react';
import classNames from 'classnames';
import QRCode from 'qrcode';
import Button from '@components/particles/Button';
import template from '@utils/vcard';
import downloadFile from '@utils/downloadFile';

const VCard = ({ data, fileName = 'vcard', label = 'Download', className, ...props }) => {
    const [qrCode, setQrCode] = useState();
    const [vCard, setvCard] = useState();

    const downloadHandler = () => downloadFile(vCard, `${fileName}.vcf`);

    useEffect(() => {
        setvCard(template(data));
    }, [data]);

    useEffect(() => {
        if(!vCard) return;

        QRCode.toDataURL(vCard, {
            margin: 0,
            scale: 8,
            errorCorrectionLevel: 'L'
        })
        .then(setQrCode)
        .catch(err => {
            console.error(err)
        })
    }, [vCard]);

    const vCardClasses = classNames(
        'vCard',
        fileName && 'button',
        !fileName && 'code',
        className
    );

    if(fileName) {
        return (
            <Button
                bordered
                icon="IconId"
                className={vCardClasses}
                onClick={downloadHandler}
            >
                {label}
            </Button>
        );
    } else {
        return (
            <div className={vCardClasses}>
                <img alt="VCard" src={qrCode} {...props} />
            </div>
        );
    }
};

export default VCard;