import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Icon from '@components/particles/Icon';
import styles from '@styles/components/particles/Button.module.scss';

const Button = ({ to, href, icon, bordered = false, children, className, ...props }) => {
    const iconName = icon || 'IconArrowUpRightCircle';

    const buttonClasses = classNames(
        styles.button,
        bordered && styles.borderedButton,
        className        
    );

    if(to) {
        return (
            <Icon className={buttonClasses} icon={iconName} {...props}>
                <Link to={to}>{children}</Link>
            </Icon>
        );
    } else if(href) {
        return (
            <Icon className={buttonClasses} icon={iconName} {...props}>
                <a href={href} {...props}>{children}</a>
            </Icon>
        );
    } else {
        return (
            <Icon className={buttonClasses} icon={iconName} {...props}>
                {children}
            </Icon>
        );
    }
};

export default Button;