import React from 'react';
import { hydrate, render } from 'react-dom';
import Context from '@context';
import Router from '@router';
import Portal from '@components/particles/Portal';
import Loader from '@components/Loader';
import './scss/styles.scss';

const rootElement = document.getElementById('root');

const AppWrapper = ({ loader = true }) => {
    return (
      <React.StrictMode>
        <Context>
            {loader && <Portal className="app-loader">
              <Loader />
            </Portal>}
            <Router />
        </Context>
      </React.StrictMode>
    );
};

if (rootElement.hasChildNodes()) {
  hydrate(
    <AppWrapper loader={false} />,
    rootElement
  );
} else {
  render(
    <AppWrapper />,
    rootElement
  );
}