const List = ({ items, wrapper = true, callback, graded = false, ...props }) => {
    if(callback) {
        return items.map(callback);
    } else {
        const Mapped = () => items.map((item, i) => {
            const style = {
                opacity: (1-((i/items.length)/2))
            };

            return <li key={i} style={graded ? style : null}>{item}</li>;
        });
        
        return wrapper ? (<ul {...props}><Mapped /></ul>) : <Mapped {...props} />;
    }
}

export default List;