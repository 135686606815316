import { useContext } from 'react';
import classNames from 'classnames';
import { AppContext } from '@context';
import useBreadcrumb from '@hooks/useBreadcrumb';
import Text from '@components/particles/Text';
import styles from '@styles/components/Footer.module.scss';
import { content } from '@root/package.json';
 
const Footer = ({ className, ...props }) => {
    const { state, menuLinks, misc, dispatch } = useContext(AppContext);

    const currentYear = new Date().getFullYear();

    const lostLabel = misc && misc.lost;
    
    const topLevelPath = useBreadcrumb();
    const { label } = menuLinks.find(link => link.path === topLevelPath) || {};

    const footerClasses = classNames(
        styles.footer,
        className,
        'animate__animated',
        'animate__fadeInUpBig'
    );

    const onLocationClickHandler = () => {
        dispatch('TOGGLE_BLUR', !state.isMenuOpen);
        dispatch('TOGGLE_MENU', !state.isMenuOpen);
    };

    return (
        <div className={footerClasses}>
            <Text styleName="small" className={styles.copyright}>&copy; {currentYear}</Text>
            <Text styleName="small" className={styles.pageNumber}>
                <span className={styles.menuLink} onClick={onLocationClickHandler}>{label || lostLabel}</span> <span>&middot;</span> <span className={styles.author}>{content.title}</span>
            </Text>
        </div>
    );
};

export default Footer;