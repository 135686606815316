import axios from 'axios';
import { api } from '@root/package.json';

export const baseURL = process.env.REACT_APP_API_URL || api;

const API = (dispatch) => {
    const instance = axios.create({
        baseURL
    });

    instance.interceptors.request.use(config => {
        dispatch('REQUEST', config.url);
        return config;
    }, error => {
        dispatch('REQUEST', error.config.url);
        return Promise.reject(error)
    });
    
    instance.interceptors.response.use(response => {
        dispatch('REQUEST', response.config.url);
        return response;
    }, error => {
        dispatch('REQUEST', error.config.url);
        return Promise.reject(error)
    });

    return instance;
};

export default API;