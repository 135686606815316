import classNames from 'classnames';
import * as icons from '@tabler/icons';
import styles from '@styles/components/particles/Icon.module.scss';

const Icon = ({ icon,  children, className, ...props }) => {
    const IconGlyph = icons[icon];

    const iconClasses = classNames(
        styles.icon,
        className
    );

    return (
        <div className={iconClasses} {...props}>
            <IconGlyph stroke={1.5} />
            <div className={styles.content}>{children}</div>
        </div>
    );
}

export default Icon;