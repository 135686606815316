export const defaultState = {
    posts: [],
    pendingRequests: [],
    isMenuOpen: false,
    isBlurred: false
};

export const stateReducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'REQUEST':
            const { pendingRequests } = state;
            const requestExists = pendingRequests.includes(payload);

            return {
                ...state,
                pendingRequests: requestExists ? pendingRequests.filter(item => item !== payload) : pendingRequests.concat(payload)
            };
        case 'ADD_POST':
            let { posts } = state;

            const post = posts.find(post => post.url === payload.url);
            
            
            if(post) {
                Object.assign(post, payload);
            } else {
                posts = state.posts.concat([payload]);
            }

            return {
                ...state,
                posts
            };
        case 'TOGGLE_MENU':
            const { isMenuOpen } = state;
            const isOpen = payload === undefined ? !isMenuOpen : payload;

            return {
                ...state,
                isMenuOpen: isOpen
            };
        case 'TOGGLE_BLUR':
            const { isBlurred } = state;
            const blur = payload === undefined ? !isBlurred : payload;

            return {
                ...state,
                isBlurred: blur
            };
        default:
        throw new Error(`Dispatched action does not exist: ${type}`);
    }
};