import { useEffect, useContext } from 'react';
import { Route, Switch, useRouteMatch, Link } from 'react-router-dom';

import { AppContext } from '@context';
import Text from '@components/particles/Text';
import Post from '@components/Post';

const Blog = () => {
    const { path, url } = useRouteMatch();

    const {
        state: {
            posts
        },
        fetchPosts
    } = useContext(AppContext);

    // eslint-disable-next-line
    useEffect(fetchPosts, []);

    return (
        <Switch>
            <Route path={`${path}`} exact>
                <>
                    <Text styleName="h1">Blog</Text>

                    {posts.map(({ title, timestamp, ...post }) => {
                        const date = new Date(timestamp * 1000);
                        const uri = post.url;

                        return (
                            <div key={date}>
                                <Link to={`${url}/${uri}`}><h1>{title}</h1></Link>
                                <h5>{date.toLocaleDateString()}</h5>
                            </div>
                        );
                    })}
                </>
            </Route>
            <Route path={`${path}/:postURL`}>
                {({ match: { params }}) => {
                    const post = posts.find(post => post.url === params.postURL);

                    return <Post {...post} />;
                }}
            </Route>
        </Switch>
    );
};

export default Blog;