import Lottie from 'react-lottie-player';
import Text from '@components/particles/Text';
import rocket from '@images/illustrations/8586-rocket-in-space.json';
import styles from '@styles/routes/NotFound.module.scss';

const NotFound = () => {
    return (
        <div className="page">
            <div className="pageInner">
                <div className={styles.wrapper}>
                    <Text styleName="h1">There's nothing here</Text>

                    <Lottie
                        loop
                        animationData={rocket}
                        play
                        className={`notFoundAnimation ${styles.animation}`}
                    />

                    <Text tagName="blockquote" className={`quote ${styles.quote}`}>
                        <p>I flew to the moon not so much to go there, but as part of developing the system that would allow it to happen.</p>
                        <Text styleName="small" className="quoteFooter">Neil Armstrong</Text>
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default NotFound;