import classNames from 'classnames';
import Menu from '@components/Menu';
import Logo from '@components/Logo';
import styles from '@styles/components/Header.module.scss';

const Header = ({ menu = true, className, ...props }) => {
    const headerClasses = classNames(
        styles.header,
        className,
        'animate__animated',
        'animate__fadeInDownBig'
    );

    return (
        <>
            <div className={headerClasses}>
                <Logo className={styles.logo} />
            </div>

            {menu && <Menu />}
        </>
    );
}

export default Header;