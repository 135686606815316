import { useState, useEffect } from 'react';
import Theme from '@components/particles/Theme';
import ReactDOM from 'react-dom';

const Portal = ({ children, className, el = 'div' }) => {
    const [container] = useState(document.createElement(el));

    if(!className) throw new Error('You must provide a className for the container.');
    
    container.classList.add(className);

    useEffect(() => {
        document.body.appendChild(container);
        
        return () => document.body.removeChild(container);
    // eslint-disable-next-line
    }, []);

    return ReactDOM.createPortal((<Theme>{children}</Theme>), container);
}

export default Portal;