import { useContext } from 'react';
import classNames from 'classnames';
import { AppContext } from '@context';
import Text from '@components/particles/Text';
import List from '@components/particles/List';
import Columns from '@components/particles/Columns';
import styles from '@styles/routes/Services.module.scss';

const Services = () => {
    const { services } = useContext(AppContext);

    return (
        <Columns>
            {services.columns.map((column, i) => {
                const columnClasses = classNames(
                    styles.servicesColumn,
                    i === 0 && styles.graded
                );

                return (
                    <div className={columnClasses} key={i}>
                        <Text styleName="h1" className={styles.servicesHeading}>{column.title}</Text>
                        <Text tagName="div" styleName="large">
                            <List className={styles.servicesList} items={column.items} graded />
                        </Text>
                    </div>
                );
            })}
        </Columns>
    );
};

export default Services;