import { useEffect, useState } from 'react';
import mapValues from 'lodash.mapvalues';
import { mobile, tablet, desktop, darkMode, landscape } from '@styles/_variables.module.scss';

const defaultBreakpoints = {
    mobile,
    tablet,
    desktop,
    darkMode,
    landscape
};

export const useMediaQueries = (mediaQueries = defaultBreakpoints) => {
    const [breakpoints, setBreakpoints] = useState({});

    const queries = mapValues(mediaQueries, q => window.matchMedia(q));

    const mapQueries = () => setBreakpoints(mapValues(queries, q => q.matches));

    // eslint-disable-next-line
    useEffect(mapQueries, []);

    useEffect(() => {
        Object.values(queries).forEach(q => q.addEventListener('change', mapQueries));

        return () => {
            Object.values(queries).forEach(q => q.removeEventListener('change', mapQueries));
        }
    // eslint-disable-next-line
    });

    return breakpoints;
}