import { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { AppContext } from '@context';
import Header from '@components/Header';
import Guide from '@components/Guide';
import Footer from '@components/Footer';
import RotateDevice from '@components/RotateDevice';
import Theme from '@components/particles/Theme';
import '@styles/components/Container.scss';

const Container = ({ children, menu = true, ...props }) => {
    const { state } = useContext(AppContext);

    const mainClasses = classNames(
        'master',
        state.isBlurred && 'blurred'
    );

    useEffect(() => {
        document.body.classList.toggle('preventScrolling', state.isBlurred);
    });

    return (
        <>
            <Theme>
                <RotateDevice />

                <Header menu={menu} />
                
                <main className={mainClasses} {...props}>
                    {children}
                </main>

                <Guide />
                <Footer />
            </Theme>
        </>
    );
};

export default Container;