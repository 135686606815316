import React from 'react';
import classNames from 'classnames';
import typography from '@utils/typography';
import styles from '@styles/components/particles/Text.module.scss';

const Text = ({ children, styleName, font, tagName, className, ...props }) => {
    const paragraphStyle = Object.keys(typography).find(typeName => typeName === styleName);
    const styleDef = typography[paragraphStyle] || typography.regular;

    const classes = classNames(
        styles[styleDef.className],
        className
    );

    return React.createElement(tagName || styleDef.tagName, {
        ...props,
        className: classes
    }, children);

    
}
    
export default Text;