import { useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { AppContext } from '@context';
import Text from '@components/particles/Text';
import { ThemeSwitch } from '@components/particles/Theme';
import styles from '@styles/components/Menu.module.scss';

const Menu = () => {
    const {
        state,
        misc,
        menuLinks,
        dispatch
    } = useContext(AppContext);

    const menuLabel = misc && misc.menu;

    const ref = useRef();

    const onMenuClickHandler = () => {
        dispatch('TOGGLE_BLUR', !state.isMenuOpen);
        dispatch('TOGGLE_MENU', !state.isMenuOpen);
    }

    const menuListClasses = classNames(
        styles.menuList,
        state.isMenuOpen && styles.menuList__open
    );
    
    const menuItemsClasses = classNames(
        styles.menuItems
    );
    
    const menuButtonClasses = classNames(
        styles.menuButton,
        !state.isMenuOpen && styles.burger
    );

    useEffect(() => {
        const preventClicks = (event) => {
            if(state.isMenuOpen && !ref.current.contains(event.target)) {
                event.stopPropagation();
                event.preventDefault();
            }
        }
        
        document.addEventListener('click', preventClicks, true);
        
        return () => document.removeEventListener('click', preventClicks, true);
    }, [state.isMenuOpen]);

    return (
        <div className={styles.menu} ref={ref}>
            <Text tagName="span" className={menuButtonClasses} onClick={onMenuClickHandler}>{menuLabel}</Text>
            <div className={menuListClasses}>
                <ul className={menuItemsClasses}>
                    {menuLinks.map(({ path, label, nofollow }, i) => {
                        const linkProps = {
                            to: path,
                            onClick: onMenuClickHandler
                        };

                        if(nofollow) linkProps.rel = 'nofollow';

                        return (
                            <li key={i}>
                                <Link {...linkProps}>
                                    <Text styleName="h2">{label}</Text>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
                <ThemeSwitch className={styles.toolbox} />
            </div>
        </div>
    );
};

export default Menu;