import { useContext, useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { AppContext } from '@context';
import Container from '@components/Container';
import NotFound from '@components/routes/NotFound';
import About from '@components/routes/About';
import Blog from '@components/routes/Blog';
import Calendar from '@components/routes/Calendar';
import Clients from '@components/routes/Clients';
import Contact from '@components/routes/Contact';
import Experience from '@components/routes/Experience';
import Home from '@components/routes/Home';
import Print from '@components/routes/Print';
import Services from '@components/routes/Services';
import ScrollToTop from '@router/ScrollToTop';
import { pageTransitionDuration } from '../scss/_variables.module.scss';

const components = {
    About,
    Blog,
    Calendar,
    Clients,
    Contact,
    Experience,
    Home,
    Services
};

const createRoutes = (routes) => routes.map(({ component, path, bleed }) => {
    const Component = components[component];
    const exact = !['/blog', '/experience'].includes(path);

    return (
        <Route key={path} path={path} exact={exact}>
            {({ match }) => {
                const pageInnerClasses = classNames(
                    'pageInner',
                    bleed && 'bleed'
                );

                return (
                    <div className="page">
                        <div className={pageInnerClasses}>
                            <Component />
                        </div>
                    </div>
                );
            }}
        </Route>
    );
});

const PageWrapper = ({ children }) => <div className="pageWrapper">{children}</div>

const AnimatedSwitch = withRouter(({ children, location }) => {
    const transitionDuration = parseInt(pageTransitionDuration);

    return (
        <TransitionGroup component={PageWrapper}>
          <CSSTransition
            key={location.key}
            timeout={transitionDuration}
            classNames="page"
            unmountOnExit
          >
            <Switch location={location}>
                {children}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      );
});

const AppRouter = () => {
    const {
        menuLinks,
        fetchContent,
        fetchProjects
    } = useContext(AppContext);

    // eslint-disable-next-line
    useEffect(fetchContent, []);
    
    // eslint-disable-next-line
    useEffect(fetchProjects, []);

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Container>
                <AnimatedSwitch>
                    {createRoutes(menuLinks)}
                    <Route path="/print" exact>
                        <div className="pageInner">
                            <Print />
                        </div>
                    </Route>
                    <Route path="*">
                        <NotFound />
                    </Route>
                </AnimatedSwitch>
            </Container>
        </BrowserRouter>
    );
}

export default AppRouter;