import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
 
const useBreadcrumb = () => {
    const location = useLocation();
    const [topPath, setTopPath] = useState(location.pathname);

    useEffect(() => {
        const newPath = location.pathname.match(/^[^]*/)[0];

        setTopPath(newPath)
    }, [location.pathname]);

    return topPath;
};

export default useBreadcrumb;