import { useEffect, useContext, useState } from 'react';
import marked from 'marked';
import { sanitize } from 'dompurify';
import { AppContext } from '@context';
import Image from '@components/Image';
import ReactDOMServer from 'react-dom/server';

marked.use({
    renderer: {
        heading: (text, level) => {
            return level === 1 ? '' : false;
        },
        image: (href, title, text) => {
            return ReactDOMServer.renderToString(<Image
                src={href}
                desc={title}
                attrib={text}
            />);
        }
    },
    gfm: true
});

const parseMarkdown = (md) => sanitize(marked(md));

const timestampToDate = (timestamp) => new Date(timestamp * 1000).toLocaleDateString();

const Post = ({ timestamp, title, url, text, ...props }) => {
    const { dispatch, fetchPost } = useContext(AppContext);
    
    const [postDate, setPostDate] = useState();
    const [postTitle, setPostTitle] = useState();
    const [postContent, setPostContent] = useState();

    useEffect(() => {
        if(!url || text) return;

        fetchPost(url)
        .then(text => {
            dispatch('ADD_POST', {
                url,
                text
            });
        })
        .catch(err => {
            console.log(err)
        });
    // eslint-disable-next-line
    }, [url, text]);

    useEffect(() => timestamp && setPostDate(timestampToDate(timestamp)), [timestamp]);
    
    useEffect(() => {        
        text && setPostContent({
            __html: parseMarkdown(text)
        });
    }, [text]);
    
    useEffect(() => {        
        title && setPostTitle({
            __html: parseMarkdown(title)
        });
    }, [title]);

    return (
        <>
            <h1 dangerouslySetInnerHTML={postTitle} />
            <h5>{postDate}</h5>
            <div dangerouslySetInnerHTML={postContent} />
        </>
    );
};

export default Post;