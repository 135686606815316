import { useState, useEffect, useContext } from 'react';
import { AppContext } from '@context';
import { useMediaQueries } from '@hooks/useMediaQueries';
import Text from '@components/particles/Text';
import styles from '@styles/components/RotateDevice.module.scss';

const RotateDevice = () => {
    const { misc } = useContext(AppContext);

    const [isComponentVisible, setIsComponentVisible] = useState(false);

    const media = useMediaQueries();
    
    useEffect(() => {
        const isMobile = !(media.tablet || media.desktop);
        const isVisible = isMobile && media.landscape;

        setIsComponentVisible(isVisible);
    // eslint-disable-next-line
    }, [media]);

    return isComponentVisible ? (
        <div className={styles.rotate}>
            <div className={styles.prompt}>
                <Text styleName="h3">{misc.rotateTitle}</Text>
                <Text styleName="small">{misc.rotateText}</Text>
            </div>
        </div>
    ) : null;
};

export default RotateDevice;