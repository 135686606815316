import { useContext, useEffect } from 'react';
import { baseURL } from '@context/api';
import { AppContext } from '@context';
import styles from '@styles/routes/Clients.module.scss';

const Clients = () => {
    const { clients, theme, setTheme } = useContext(AppContext);

    useEffect(() => {
        setTheme('inverted');

        return () => setTheme(undefined);
    // eslint-disable-next-line
    }, [theme])

    return (
        <div className={styles.clients}>
            {clients.map((client, i) => <img key={i} className={styles.logo} src={`${baseURL}/${client.image}`} alt={client.name} />)}
        </div>
    );
};

export default Clients;