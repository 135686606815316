import { createContext, useState, useReducer } from 'react';

import { defaultState, stateReducer } from './state';

import API from './api';

export const AppContext = createContext({});

const endpoints = {
    content: 'content.json',
    projects: 'projects.json',
    posts: 'posts.json',
    post: 'posts'
};

const getThemePreferences = () => {
    const savedPreferences = localStorage.getItem('useDarkMode');

    if(savedPreferences === null) {
        return true;
    } else {
        return savedPreferences === 'true' ? true : false;
    }
}

const ContextProvider = ({ children, ...props }) => {
    const [error, setError] = useState(false);
    
    const [about, setAbout] = useState({});
    const [clients, setClients] = useState([]);
    const [contact, setContact] = useState({});
    const [experience, setExperience] = useState({});
    const [intro, setIntro] = useState({});
    const [menuLinks, setMenuLinks] = useState([]);
    const [misc, setMisc] = useState({});
    const [services, setServices] = useState([]);
    
    const [darkTheme, setDarkThemeCallback] = useState(getThemePreferences());
    const [theme, setTheme] = useState();
    
    const [menuOpen, setMenuOpen] = useState(false);
    const [projects, setProjects] = useState([]);

    const [state, dispatchMethod] = useReducer(stateReducer, defaultState);

    const dispatch = (type, payload) => dispatchMethod({ type, payload });

    const setDarkTheme = async (value) => {
        setDarkThemeCallback(value);

        return localStorage.setItem('useDarkMode', value);
    }

    const api = API(dispatch);

    const fetchContent = () => api.get(endpoints.content)
    .then(response => {
        const {
            about,
            clients,
            contact,
            experience,
            intro,
            menu,
            misc,
            services
        } = response.data;

        setAbout(about);
        setClients(clients);
        setContact(contact);
        setExperience(experience);
        setIntro(intro);
        setMisc(misc);
        setServices(services);

        setMenuLinks(menu.filter(r => !r.disabled));
    })
    .catch(err => setError(err));
    
    const fetchProjects = () => api.get(endpoints.projects)
    .then(response => setProjects(response.data))
    .catch(err => setError(err));
    
    const fetchPosts = () => api.get(endpoints.posts)
    .then(response => response.data.forEach(post => dispatch('ADD_POST', post)))
    .catch(err => setError(err));
    
    const fetchPost = (post) => api.get(`${endpoints.post}/${post}`)
    .then(response => response.data)
    .catch(err => setError(err));

    const store = {
        error,

        about,
        clients,
        contact,
        experience,
        intro,
        misc,
        services,
        projects,

        darkTheme,
        menuLinks,
        menuOpen,
        state,
        theme,

        setDarkTheme,
        setMenuOpen,
        setTheme,  

        dispatch,
        fetchContent,
        fetchProjects,
        fetchPosts,
        fetchPost
    };

    return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default ContextProvider;