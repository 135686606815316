import classNames from 'classnames';
import { useMediaQueries } from '@hooks/useMediaQueries';
import styles from '@styles/components/particles/Columns.module.scss';

const Columns = ({ columnCount, children, className, ...props }) => {
    const classes = classNames(
        styles.columns,
        className
    );

    const media = useMediaQueries();

    const columns = media.desktop ? columnCount || children.length : 1;
    
    return (
        <div className={classes} {...props} style={{ columnCount: columns }}>
            {children}
        </div>
    );
}
    
export default Columns;