const Calendar = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const numberOfDays = new Date(year, month, 0).getDate();

    return (
        <div>
            <h1>Calendar</h1>
            <p>{month}/{year}</p>
            <p>{numberOfDays}</p>
        </div>
    );
};

export default Calendar;