import { useState } from 'react';
import classNames from 'classnames';
import Text from '@components/particles/Text';
import Overlay from '@components/Overlay';
import styles from '@styles/components/Image.module.scss';

const Image = ({ src, className, desc, attrib, alt = 'Photo', zoomable = true, ...props}) => {
    const [zoom, setZoom] = useState(false);

    const togglePhoto = () => setZoom(!zoom);

    const zoomedOutClasses = classNames(
        styles.imageWrapper,
        zoomable && styles.zoomable,
        styles[className]
    );
    
    const zoomedInClasses = classNames(
        styles.imageWrapper,
        zoomable && styles.zoomable,
        styles.zoomedIn
    );

    const figureClasses = classNames(
        styles.figure
    );

    return (
        <>
        <div className={zoomedOutClasses}>
            <figure className={figureClasses}>
                <img className={styles.image} onClick={togglePhoto} src={src} alt={alt} />
                <figcaption className={styles.figcaption}>
                    <Text styleName="small" className={styles.desc}>{desc}</Text>
                    <Text styleName="micro" className={styles.attrib}>Photo courtesy of {attrib}</Text>
                </figcaption>
            </figure>
        </div>

        <Overlay isVisible={zoom} closeButton={false}>
            <div className={zoomedInClasses}>
                <figure className={figureClasses}>
                    <img className={styles.image} onClick={togglePhoto} src={src} alt={alt} />
                    <figcaption className={styles.figcaption}>
                        <Text styleName="small" className={styles.desc}>{desc}</Text>
                        <Text styleName="micro" className={styles.attrib}>Photo courtesy of {attrib}</Text>
                    </figcaption>
                </figure>
            </div>
        </Overlay>
        </>
    );
}

export default Image;