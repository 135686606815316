import { Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import ProjectTimeline from '@components/ProjectTimeline';
import ProjectOverlay from '@components/ProjectOverlay';

const Experience = () => {
    const { path } = useRouteMatch();
    const location = useLocation()
    const background = location.state && location.state.background;

    return (
        <>
            <Switch location={background || location}>
                <Route path={`${path}`} exact>
                    <ProjectTimeline />
                </Route>
                <Route path={`${path}/:client`}>
                    <ProjectOverlay mainRoute={path} />
                </Route>
            </Switch>

            {background && <Route path={`${path}/:client`}>
                <ProjectOverlay />
            </Route>}
        </>
    );
};

export default Experience;