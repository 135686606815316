import { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { AppContext } from '@context';
import Theme from '@components/particles/Theme';
import styles from '@styles/components/Overlay.module.scss';
import { pageTransitionDuration } from '../scss/_variables.module.scss';

const Overlay = ({
    el = 'div',
    closeButton = true,
    appear = false,
    isVisible,
    onBeforeClose,    
    children,
    className,
    transitionClassNames,
    closeButtonClassName,
    contentClassName,
    ...props
}) => {
    const { dispatch } = useContext(AppContext);
    const [visible, setVisible] = useState(isVisible);

    const transitionDuration = parseInt(pageTransitionDuration);

    const overlayClasses = classNames(
        'overlay',
        styles.overlay,
        className
    );
    
    const closeButtonClasses = classNames(
        'overlayClose',
        styles.close,
        closeButtonClassName
    );
    
    const contentClasses = classNames(
        'overlayContent',
        styles.content,
        contentClassName
    );

    const closeOverlay = () => {
        if(typeof onBeforeClose === 'function') onBeforeClose();

        setVisible(!visible)
    };

    useEffect(() => {
        dispatch('TOGGLE_BLUR', visible);
    // eslint-disable-next-line
    }, [visible]);

    useEffect(() => {
        setVisible(isVisible);
    }, [isVisible]);

    return ReactDOM.createPortal(
        <Theme>
            <CSSTransition
                in={visible}
                appear={appear}
                classNames={transitionClassNames || {
                    appearActive: styles['overlay-appear-active'],
                    enterActive: styles['overlay-enter-active'],
                    exitActive: styles['overlay-exit-active'],
                    exit: styles['overlay-exit']
                }}
                timeout={transitionDuration}
                mountOnEnter
                unmountOnExit
            >
                    <div className={overlayClasses} {...props}>
                        {closeButton && <span onClick={closeOverlay} className={closeButtonClasses}>Close</span>}
                        <div className={contentClasses}>
                            {children}
                        </div>
                    </div>
            </CSSTransition>
        </Theme>,
        document.querySelector('#overlay')
    );

}

export default Overlay;