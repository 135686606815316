const template = ({
    firstName,
    lastName,
    email,
    phone,
    city,
    country,
    website
}) => `
BEGIN:VCARD
VERSION:3.0
FN;CHARSET=UTF-8:${firstName} ${lastName}
N;CHARSET=UTF-8:${lastName};${firstName};;;
EMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${email}
TEL;TYPE=WORK,VOICE:${phone}
ADR;CHARSET=UTF-8;TYPE=WORK:;;;${city};;;${country}
URL;type=WORK;CHARSET=UTF-8:${website}
END:VCARD
`;

export default template;