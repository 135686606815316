import { useContext } from 'react';
import classNames from 'classnames';
import { AppContext } from '@context';
import Text from '@components/particles/Text';
import styles from '@styles/components/Intro.module.scss';

const Intro = () => {
    const { intro } = useContext(AppContext);
    
    const introClasses = classNames(
        styles.intro,
        'animate__animated',        
        'animate__fadeInUp',        
    );

    return <div className={introClasses}>
        <Text
            styleName="intro"
            className={styles.introText}
        >{intro && intro.text}</Text>
    </div>;
};

export default Intro;