import { useContext, useEffect, useState } from 'react';
import { content } from '@root/package.json';
import { AppContext } from '@context';
import { useMediaQueries } from '@hooks/useMediaQueries';
import Icon from '@components/particles/Icon';
import Text from '@components/particles/Text';
import VCard from '@components/VCard';
import styles from '@styles/routes/Contact.module.scss';

const Contact = () => {
    const { contact } = useContext(AppContext);
    const labels = contact.labels || {};

    const media = useMediaQueries();

    const { firstName, lastName, city, country } = contact;
    
    const [email, setEmail] = useState(contact.email);
    const [phone, setPhone] = useState(contact.phone);
    const [cardData, setCardData] = useState({});
    const [fileName, setFileName] = useState();

    useEffect(() => {
        setCardData({
            firstName,
            lastName,
            email,
            phone,
            city,
            country,
            website: `https://${content.domain}`
        });
    }, [email, phone, firstName, lastName, city, country]);

    useEffect(() => {
        const isDesktop = media.desktop;
        const vCardFileName = !isDesktop && `${firstName}-${lastName}`.toLowerCase();

        setFileName(vCardFileName);
    }, [media, firstName, lastName]);

    useEffect(() => {
        setEmail(`hi@${content.domain}`);
    }, []);
    
    useEffect(() => {
        setPhone(`+44 0${contact.phone * 11}`);
    }, [contact.phone]);

    return (
        <Text tagName="div" styleName="large" className={styles.contact}>
            <Text styleName="h1">{contact.title}</Text>
            <p>{contact.text}</p>

            {!fileName && <p>{labels.qrCode}</p>}

            <VCard
                data={cardData}
                fileName={fileName}
                label={labels.addToContacts}
                className={styles.qrCode}
            />

            <p>{labels.alternatively}</p>

            <div className={styles.contactInfo}>
                <Icon icon="IconMail">
                    <a href={`mailto:${email}`} rel="nofollow">{email}</a>
                </Icon>
            </div>

            <div className={styles.contactInfo}>
                <Icon icon="IconPhone">
                    <a href={`tel:${phone}`}>{phone}</a>
                </Icon>
            </div>
        </Text>
    );
};

export default Contact;