import { useContext } from 'react';
import { useHistory } from "react-router-dom";
import { sanitize } from 'dompurify';
import { AppContext } from '@context';
import Text from '@components/particles/Text';
import Columns from '@components/particles/Columns';
import styles from '@styles/routes/About.module.scss';

const About = () => {
    const history = useHistory();
    const { about } = useContext(AppContext);
    const paragraphs = about && about.text.split('\n');

    const handleLinks = (event) => {
        event.preventDefault()

        if (event.target.tagName === 'A') history.push(event.target.getAttribute('href'));
    }

    return (
        <div className={styles.about}>
            <Text styleName="h1">{about.title}</Text>
            
            <Columns columnCount={2}>
                {paragraphs.map((p, i) =>
                    <Text key={i} styleName="large" dangerouslySetInnerHTML={{__html: sanitize(p)}} onClick={handleLinks} />
                )}
            </Columns>
    </div>
    );
};

export default About;