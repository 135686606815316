const styles = {
    h1: {
        tagName: 'h1',
        className: 'h1'
    },
    intro: {
        tagName: 'h1',
        className: 'intro'
    },
    h2: {
        tagName: 'h2',
        className: 'h2'
    },
    year: {
        tagName: 'h2',
        className: 'year'
    },
    excerpt: {
        tagName: 'h2',
        className: 'excerpt'
    },
    book: {
        tagName: 'p',
        className: 'book'
    },
    summary: {
        tagName: 'p',
        className: 'summary'
    },
    h3: {
        tagName: 'h3',
        className: 'h3'
    },
    micro: {
        tagName: 'p',
        className: 'micro'
    },
    small: {
        tagName: 'p',
        className: 'small'
    },
    regular: {
        tagName: 'p',
        className: 'regular'
    },
    medium: {
        tagName: 'p',
        className: 'medium'
    },
    large: {
        tagName: 'p',
        className: 'large'
    }
};

export default styles;